<template>
	<div class="enterprise" style="padding-top: 16px;">

		<div class="breadcrumbTop">
			<div class="tips_top_box" style="display: flex;align-items: center;margin-bottom:10px">
				<a-input allow-clear style='width:200px' v-model:value="where.keyword" placeholder="请输入工作区域"
				  @change="searchChange" >
				   <template #prefix>
				        <a-icon type="search" />
				        </template>
				</a-input>
				<div style="text-align: right;flex: 1;">
					<a-button type="primary" @click='visible2=true'>添加工作区域
					</a-button>
				</div>
			</div>
			<div class="add_info">
				<div style="flex: 1;">
					<a-table :dataSource="dataSource" :columns="columns" :defaultExpandAllRows="true">
						<template slot='name' slot-scope="text,record,index" >
							<div class="o_g_a_c_name">
								<div class="o_g_a_c_name_box">
									<span class="nameBTex" >{{record.name}}</span>
									<div style='margin-left:12px;'>
										<span class="cq_color" @click="addSubordinate(record)">新增下级</span>
										
										<span style='margin-left:12px;' class="cq_color" @click="edit_info(record)">编辑</span>
									</div>
								</div>
							</div>
						</template>
						<template slot='op' slot-scope="text,record,index" >
							<div class="o_g_a_c_name">
								<a-popconfirm
								    title="是否确定删除?"
								    ok-text="确认"
								    cancel-text="取消"
								    @confirm="confirmDel(record)"
								  
								  >
								   <div class="o_g_a_c_name_box">
								   	
								   	<span class="cq_error" >删除</span>
								   </div>
								  </a-popconfirm>
								
							</div>
						</template>
					</a-table>
				</div>
			</div>
		</div>
		<a-modal v-model:visible="visible2" @cancel='cancel_add' :title="editaddType=='add'?'新增工作区域':'编辑工作区域'"
			@ok="addOk2">
			<a-form :model='formstatusadd' :label-col="labelColEdit" :wrapper-col="wrapperColEdit">
				<a-form-item required name='name' label="工作区域"
					:rules="[{ required: true, message: '工作地点名称不能为空',tagger:'blur' }]">
					<a-input placeholder="请输入工作区域名称" v-model:value="formstatusadd.name" />
				</a-form-item>
				<a-form-item label="上级工作区域">
					<a-tree-select :replaceFields="{children:'children', title:'name', key: 'id',value:'id' }"
						v-model:value="formstatusadd.pid" show-search style="width: 100%"
						:dropdown-style="{ maxHeight: '400px', overflow: 'auto' }" placeholder="请选择上级"
						tree-default-expand-all :tree-data="dataSource2">
					</a-tree-select>
				</a-form-item>
				<a-form-item label="区域代码">
					<a-input v-model:value="formstatusadd.code" />
				</a-form-item>
			</a-form>
		</a-modal>

		<a-modal v-model:visible="visibleedit" @cancel='cancel_add' title="编辑区域代码" @ok="addOk2">
			<a-form :label-col="labelColEdit" :wrapper-col="wrapperColEdit">
				<a-form-item label="区域代码">
					<a-input v-model:value="formstatusadd.code" />
				</a-form-item>
			</a-form>
		</a-modal>
	</div>
</template>

<script>
	import {
		getWorkLocationTree,
		removeWorkLocation,
		modifyWorkLocation,
		modifyWorkLocationCategory,
		workLocationCategory,
		removeWorkLocationCategory,
		workLocationSort,
		workLocation
	} from '@/api/organization.js'
	import options from './city.js'

	import {
		message,
		TreeDataItem,
		TreeDragEvent,
		DropEvent,
		Modal
	} from 'ant-design-vue';


	export default {
		components: {
		
		},
		data() {
			return {
				editaddType: 'add',
				api: workLocation,
				dataSource: [],
				dataSource2: [],
				where: {
					category_id: 0,
				},
				expandedKeys: [],
				selectedKeys: [],
				treeData: [{
					name: '全部',
					id: 0,
					children: []
				}],
				addressLitst: [],
				options,
				search_form: false,
				name: '',
				addType: 'add',
				addTypeVisible: false,
				visible: false,
				visible2: false,
				formstatusadd: {},
				type_list: [{
					label: 'shhs'
				}, {
					label: 'shhs'
				}],
				all_list: [{
					name: 'shhs'
				}, {
					name: 'shhs'
				}],
				labelColEdit: {
					span: 6
				},
				wrapperColEdit: {
					span: 16
				},
				wrapperCol: {
					span: 12
				},
				select_list: [],
				type: '',
				formstatus: {},
				titelIndex: '',
				editType: 'add',
				visibleedit: false,
				columns: [{
						title: '工作区域',
						dataIndex: 'name',
						scopedSlots: {
							customRender: "name"
						}
					},
					{
						title: '层级',
						dataIndex: 'level',
						
					},
					{
						title: '在职人数',
						dataIndex: 'user_num',

					},
					{
						title: '区域代码',
						dataIndex: 'code',
					},
					{
							title: '',
							dataIndex: 'op',
							scopedSlots: {
								customRender: "op"
							}
						},
				],
			}

		},
		created() {
			this.type = this.$route.query.type

			this.get_WorkLocationTree()
		},
		methods: {
			confirmDel(item){
				removeWorkLocationCategory({data:{
					id:item.id
				}}).then(res => {
					this.get_WorkLocationTree()
				})
			},
			addSubordinate(item){
				this.formstatusadd.pid = item.id
				this.visible2 = true
			},
			edit_info(item){
				this.formstatusadd =JSON.parse(JSON.stringify(item))
				this.visible2 = true
				this.editaddType = 'edit'
			},
			cancel_add() {
				this.visible2 = false;
				this.formstatusadd = {}
				this.visibleedit = false
				this.editaddType = 'add'
			},
			setUpLaderClick(item) {
				this.formstatusadd = JSON.parse(JSON.stringify(item))
				this.visibleedit = true
			},
			searchChange(){
				this.get_WorkLocationTree()
			},
			get_WorkLocationTree() {
				workLocationCategory({data:{
					...this.where
				}}).then(res => {
					this.dataSource = res.data.list
					this.dataSource2 =JSON.parse(JSON.stringify(res.data.list)) 
				})
			},
			addOk2() {
				modifyWorkLocationCategory({
					data: {
						...this.formstatusadd
					}
				}).then(res => {
					this.visible2 = false
					this.visibleedit = false
					this.formstatusadd = {}
					this.editaddType = 'add'
					this.get_WorkLocationTree()
				})
			},

		},

	}
</script>

<style lang="less" scoped>
	@import url("../../assets/less/app.less");
	@import 'info.less';

	.enterprise {
		background-color: #fff;
	}

	.breadcrumbTop {
		flex: 1;
	}

	.table_list_body {
		margin: 0;
		padding: 0;
	}

	.check {
		color: @cq_color;
		cursor: pointer;
	}

	.del {
		color: @cq_error;
	}

	.FieldSettingsBox {
		border: none;
		border-right: 1px solid @cq_line_e;
		margin-top: 12px;
		height: auto;
	}

	.FieldSettingsBoxTitle {
		display: flex;
		width: 100%;
		padding: 8px 12px;
		border-bottom: 1px solid @cq_line_e;

		span:nth-child(2) {
			flex: 4;
			margin-left: 8px;
		}

		span:nth-child(3) {
			flex: 1;
		}

		span:nth-child(4) {
			color: #fff;

		}
	}

	.FieldSettingsBoxHeader {
		background: #f5f8fa;
	}

	.FieldSettingsBoxTitle:hover {
		background: #f5f8fa;

		span:nth-child(4) {
			color: #666;
		}

	}

	.FieldSettingsBoxActive {
		border-right: 3px solid @cq_color;
		background: #f8f8f9;
	}

	.add_info {
		display: flex;
		// padding: 12px;
	}

	.show_type {
		border-left: 3px solid #0079FF;
		padding-left: 12px;
		width: 70%;
	}

	.show_type_setting {
		width: 20%;
		text-align: right;
	}

	.w100 {
		width: 100px;
		text-align: left;
	}

	.table_list_body {
		margin-top: -12px;
	}

	/deep/.ant-tree.ant-tree-directory>li span.ant-tree-node-content-wrapper::before,
	/deep/.ant-tree.ant-tree-directory .ant-tree-child-tree>li span.ant-tree-node-content-wrapper::before,
	/deep/.ant-tree li .ant-tree-node-content-wrapper,
	/deep/.ant-tree li span.ant-tree-switcher,
	/deep/.ant-tree li span.ant-tree-iconEle {
		height: 32px;
		line-height: 32px;

	}

	/deep/.ant-tree.ant-tree-directory>li.ant-tree-treenode-selected>span.ant-tree-node-content-wrapper::before,
	/deep/.ant-tree.ant-tree-directory>li span.ant-tree-node-content-wrapper.ant-tree-node-selected {
		background: #f7f8fa;
		color: @cq_color;
	}

	/deep/.ant-tree.ant-tree-directory>li span.ant-tree-node-content-wrapper.ant-tree-node-selected {
		background: #f7f8fa;
		color: @cq_color;
	}

	/deep/.ant-tree.ant-tree-directory .ant-tree-child-tree>li.ant-tree-treenode-selected>span.ant-tree-node-content-wrapper::before {
		background: #f7f8fa;
		color: @cq_color;
	}

	/deep/.ant-row {
		margin-bottom: 12px;
	}

	.tips_top_box {
		margin-bottom: 0px
	}

	.op {
		.FormOutlined {
			color: transparent;
		}
	}

	.op:hover {
		.FormOutlined {
			color: @cq_color;
		}
	}



	.o_g_a_c_name {
		display: inline-block;
		cursor: pointer;

		.anticon-book {
			margin-right: 5px;
		}

		.o_g_a_c_name_box {
			display: flex;
			align-items: center;

			.nameBTex:hover {
				color: #397dee;
			}

			.operateBox {
				min-width: 180px;

				.operate {
					color: #397dee;
					font-size: 12px;
					margin: 0 10px;
					display: none;

				}

				.operate:hover {
					color: #7aadff;
				}
			}

			.operateBoxCopy {
				display: flex;

				.operate {
					margin: 0 10px;
				}
			}

		}

	}

	.o_g_a_c_name:hover .operateBox .operate {
		display: inline-block;
	}

	.commonBox {
		display: flex;

		.anticon-form {
			margin-left: 10px;
			display: none;
			cursor: pointer;
			color: #397dee;
		}
	}

	.commonBox:hover .anticon-form {
		display: inline-block;
	}

	.cq_color {
		color: transparent;
	}

	.cq_error {
		color: transparent;
	}

	/deep/.ant-divider {
		background: transparent;
	}

	/deep/.ant-table-tbody>tr:hover {
		.cq_color {
			color: @cq_color;
		}

		.cq_error {
			color: @cq_error;
		}

		/deep/.ant-divider {
			background: #f0f0f0;
		}
	}
</style>